import './Maintenance.scss';

function Maintenance() {
    
    return (
        <div className='maintenance__container'>
            <h1>Sorry! We are under maintenance currently!!</h1>
        </div>
    );
}

export default Maintenance